<template>
  <v-card outlined>
    <v-card-text
      v-cloak
      @drop.prevent="addDropFile"
      @dragover.prevent="dragover = true"
      @dragenter.prevent="dragover = true"
      @dragleave.prevent="dragover = false"
      :class="{ 'grey lighten-3': dragover }"
    >
      <div class="flex">
        <div class="w-full h-auto relative">
          <div class="d-flex flex-column justify-center align-center pa-2">
            <v-avatar color="grey lighten-3" rounded class="mb-2">
              <v-icon>mdi-upload</v-icon>
            </v-avatar>
            <h5 class="text-h5">Ziehe Dateien hierher</h5>
            <span class="mt-2 mb-3">oder</span>
            <v-btn depressed outlined text @click="onFileClick">
              Dateien auswählen
            </v-btn>
            <input
              type="file"
              ref="fileInput"
              v-show="false"
              @change="onFileChange"
              :multiple="multiple"
              :accept="acceptedFileTypes.join(',')"
            />
            <div v-if="unsupportedFileTypeMessage" class="error--text">
              {{ unsupportedFileTypeMessage }}
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-divider v-if="files.length"></v-divider>
    <v-card-text v-if="files.length">
      <div class="mb-2">Ausgewählte Dateien</div>
      <v-row class="match-height">
        <template v-for="(file, index) in files">
          <v-col cols="12" sm="6" :key="index">
            <v-card :ripple="false" outlined>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon>mdi-file</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ file.name }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ file.size / 1000 }} KB</v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-action
                  ><v-btn
                    text
                    color="error"
                    block
                    @click.stop="removeFile(index)"
                  >
                    <v-icon left small>mdi-delete</v-icon>Löschen
                  </v-btn></v-list-item-action
                >
              </v-list-item>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "file-upload-dropzone",
  props: {
    files: {},
    acceptedFileTypes: {
      type: Array,
      default: () => ["*"], // Allow all file types by default
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dragover: false,
      unsupportedFileTypeMessage: "",
    };
  },
  model: {
    prop: "files",
    event: "filesChange",
  },
  computed: {
    filesLocal: {
      get: function () {
        return this.files;
      },
      set: function (value) {
        this.$emit("filesChange", value);
      },
    },
  },
  methods: {
    addDropFile(e) {
      this.dragover = false;
      const files = Array.from(e.dataTransfer.files).filter(this.checkFileType);
      this.filesLocal = [...this.filesLocal, ...files];
    },
    removeFile(index) {
      if (index > -1) this.files.splice(index, 1);
    },
    checkFileType(file) {
      const fileType = file.type;
      const mainType = fileType.split("/")[0];
      if (
        !this.acceptedFileTypes.includes(fileType) &&
        !this.acceptedFileTypes.includes(`${mainType}/*`) &&
        !this.acceptedFileTypes.includes("*")
      ) {
        this.unsupportedFileTypeMessage = `Unzulässiges Dateiformat: ${file.type}`;
        return false;
      }
      this.unsupportedFileTypeMessage = "";
      return true;
    },
    onFileClick() {
      this.$refs.fileInput.click();
    },
    onFileChange(e) {
      const files = Array.from(e.target.files).filter(this.checkFileType);
      this.filesLocal = [...this.filesLocal, ...files];
      this.$refs.fileInput.value = null; // Reset the input to allow selecting the same file again
    },
  },
};
</script>
